<template>
  <el-main>
    <el-form ref="form" :rules="rules" :model="addForm" label-width="110px">
      <el-form-item label="欢迎语内容：" prop="content">
        <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="addForm.content"></el-input>
      </el-form-item>
      <el-form-item label="是否发送附件：">
        <el-radio-group v-model="is_attachments">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="is_attachments" label="添加附件：">
        <div class="tips">1.选中附件类型 2.点击添加附件按钮</div>
        <EnclosureComponents :data="attachments" :nowUpdateIndex="nowUpdateIndex" :nowUpdateItem="nowUpdateItem" :nowAddEnclosure="nowAddEnclosure"></EnclosureComponents>
        <p style="color: #f56c6c">{{ error.attachments }}</p>
      </el-form-item>
    </el-form>
    <ShowPhone v-if="is_attachments" class="showPhone" :data="attachments"></ShowPhone>
    <Preservation @preservation="save"></Preservation>
  </el-main>
</template>
<script>
import { bus } from '@/util/bus';
import { checkAttachments } from '@/util/util.js';
import EnclosureComponents from '../enterpriseWechat/components/enclosureComponents';
import ShowPhone from '../enterpriseWechat/components/phoneShow';
import Preservation from '@/components/preservation';
export default {
  components: {
    EnclosureComponents,
    ShowPhone,
    Preservation,
  },
  data() {
    return {
      addForm: {
        content: '',
      },
      is_attachments: 1,
      attachments: [],
      nowUpdateItem: null,
      nowUpdateIndex: null,
      nowAddEnclosure: 'image',
      error: {
        attachments: '',
      },
      rules: {
        content: { required: !0, message: '请填写欢迎语内容', trigger: 'blur' },
      },
    };
  },
  mounted() {
    bus.$on('changeNowUpdate', (item, index) => {
      this.nowUpdateItem = item;
      this.nowUpdateIndex = index;
      this.nowAddEnclosure = item.msgtype;
    });
    bus.$on('deleteThisFj', index => {
      this.attachments.splice(index, 1);
      this.nowUpdateItem = null;
      this.nowUpdateIndex = null;
      this.nowAddEnclosure = 'image';
    });
  },
  beforeDestroy() {
    bus.$off(['changeNowUpdate', 'deleteThisFj']);
  },
  created() {
    this.$axios.post(this.$api.user.wxwork.get_welcome_msg).then(res => {
      if (res.code == 0) {
        let info = res.result;
        if (info.hasOwnProperty('content')) this.addForm.content = info.content;
        if (info.hasOwnProperty('is_attachments')) this.is_attachments = info.is_attachments;
        if (info.attachmentss) this.attachments = info.attachmentss;
      }
    });
  },
  methods: {
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let { attachmentsFlag, errorTxt, errIndex } = checkAttachments(this.attachments);
          if (!attachmentsFlag) {
            this.error.attachments = errorTxt;
            this.nowAddEnclosure = this.attachments[errIndex].msgtype;
            this.nowUpdateIndex = errIndex;
            this.nowUpdateItem = this.attachments[errIndex];
            return;
          } else {
            this.error.attachments = '';
          }
          let data = {
            content: this.addForm.content,
            is_attachments: this.is_attachments,
          };
          if (this.is_attachments) data.attachments = this.attachments;
          this.$axios.post(this.$api.user.wxwork.set_welcome_msg, data).then(res => {
            if (res.code == 0) {
              this.$message.success('保存成功');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  display: flex;
  justify-content: space-between;
  background: #fff;
  .el-form {
    width: 700px;
  }
}
</style>
